import { useTheme } from "@material-ui/core"

export const CategoryStyles = (theme = useTheme()) => ({
  image: {
    width: theme.typography.pxToRem(300),
    height: "auto",
    backgroundColor: "#D3D5D7",
  },
  card: {
    backgroundColor: "#85acff",
    cursor: "pointer",
    marginBottom: theme.typography.pxToRem(20),
  },
})
