import * as React from "react"

import Layout from "../components/Layout"
import { getCategoryList, PageHeader } from "../components/partials"
import {
  Badge,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core"
import { CategoryStyles } from "../styles/CategoryStyles"
import BuyForm from "../components/BuyForm"
import { useCart } from "../useCart/useCart"
import Image from "../components/Image"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { Delete } from "@material-ui/icons"
import { navigate } from "gatsby"
import { CartItem, ConsumableCartItem } from "../useCart/UseCartTypes"
import { Consumable, Model } from "../types/Types"
import { isEmpty } from "lodash"
import { useConsumableCart } from "../useCart/useConsumableCart"

type Props = {
  classes: {
    image: string
    card: string
  }
}

type QuantityButtonProps = {
  item: Model
  modelDetails: string
  quantity: number
  handleQuantityChange: (item: CartItem) => void
}

type ConsumableQuantityButtonProps = {
  item: string
  quantity: number
  consumableId: string
  handleQuantityChange: (item: ConsumableCartItem) => void
}

const QuantityButtons = ({
  item,
  modelDetails,
  quantity,
  handleQuantityChange,
}: QuantityButtonProps) => {
  return (
    <Grid item container alignItems="center">
      <Grid item>
        <IconButton
          onClick={() =>
            handleQuantityChange({ item, modelDetails, quantity: quantity - 1 })
          }
        >
          <RemoveIcon />
        </IconButton>
      </Grid>
      <Grid item>{quantity}</Grid>
      <Grid item>
        <IconButton
          onClick={() =>
            handleQuantityChange({ item, modelDetails, quantity: quantity + 1 })
          }
        >
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const ConsumableQuantityButtons = ({
  item,
  quantity,
  consumableId,
  handleQuantityChange,
}: ConsumableQuantityButtonProps) => {
  return (
    <Grid item container alignItems="center">
      <Grid item>
        <IconButton
          onClick={() =>
            handleQuantityChange({ item, consumableId, quantity: quantity - 1 })
          }
        >
          <RemoveIcon />
        </IconButton>
      </Grid>
      <Grid item>{quantity}</Grid>
      <Grid item>
        <IconButton
          onClick={() =>
            handleQuantityChange({ item, consumableId, quantity: quantity + 1 })
          }
        >
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const Cart = ({ classes }: Props) => {
  const categories = getCategoryList()

  const redirect = target => navigate(`/products/${target.toLowerCase()}`)
  const consumableRedirect = target =>
    navigate(`/consumable/${target.toLowerCase()}`)

  const { cart, handleAdd, handleRemove, handleQuantityChange } = useCart()
  const {
    cart: consumableCart,
    handleAdd: consumableAdd,
    handleRemove: consumableRemove,
    handleQuantityChange: consumableChange,
  } = useConsumableCart()

  return (
    <Layout
      HeaderComponent={
        <PageHeader id={"categoriesHeader"} header="Your Cart" />
      }
      hasFeatured={true}
    >
      <Grid container>
        <Grid item container xs={12} md={6} style={{ height: "fit-content" }}>
          {!isEmpty(cart) || !isEmpty(consumableCart) ? (
            <>
              {cart.map(cartItem => (
                <Grid
                  item
                  container
                  xs={12}
                  md={10}
                  alignItems={"center"}
                  justify={"space-between"}
                  style={{
                    outline: "1px solid black",
                    marginBottom: "8px",
                    padding: "8px",
                  }}
                >
                  <Grid
                    item
                    style={{ height: "100px", cursor: "pointer" }}
                    onClick={() => redirect(cartItem.item.model.slug)}
                    xs={2}
                    md={2}
                  >
                    <Image
                      alt={cartItem.item.model.title}
                      filename={cartItem.item.model.metadata.imageurl.url}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <QuantityButtons
                      item={cartItem.item}
                      modelDetails={cartItem.modelDetails}
                      quantity={cartItem.quantity}
                      handleQuantityChange={handleQuantityChange}
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <IconButton onClick={() => handleRemove(cartItem)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>{cartItem.item.model.metadata.manufacturer}</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>
                      {cartItem.item.model.title + " " + cartItem.modelDetails}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              {consumableCart.map(cartItem => (
                <Grid
                  item
                  container
                  xs={12}
                  md={10}
                  alignItems={"center"}
                  justify={"space-between"}
                  style={{
                    outline: "1px solid black",
                    marginBottom: "8px",
                    padding: "8px",
                  }}
                >
                  <Grid
                    item
                    style={{ height: "100px", cursor: "pointer" }}
                    onClick={() => consumableRedirect(cartItem.consumable.slug)}
                    xs={2}
                    md={2}
                  >
                    <Image
                      alt={cartItem.consumableId}
                      filename={cartItem.consumable.metadata.image.url}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <ConsumableQuantityButtons
                      item={cartItem.item}
                      consumableId={cartItem.consumableId}
                      quantity={cartItem.quantity}
                      handleQuantityChange={consumableChange}
                    />
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <IconButton onClick={() => consumableRemove(cartItem)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>{cartItem.item}</Typography>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <Typography> Your cart is empty </Typography>
          )}
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid item xs={12} md={12}>
            <Typography variant={"body1"}>
              Due to the nature of our business we will call you with a quote
              after you submit your cart to us to ensure we can meet all your
              machinery needs. Please fill out your details below and we will
              contact you soon !
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <BuyForm />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default withStyles(CategoryStyles)(Cart)
